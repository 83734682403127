<template>
  <div class="OrderTable">
    <el-table v-if="tabType === '00'" key="up" :data="tableList" border style="width: 100%" id="excelOrder">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
      <el-table-column prop="merchantName" label="商家名称" align="center"></el-table-column>
      <el-table-column prop="createStaffName" label="下单人姓名" align="center"></el-table-column>
      <el-table-column prop="createStaffMobile" label="下单账号" align="center"></el-table-column>
      <el-table-column prop="price" label="充值金额（元）" align="center"></el-table-column>
      <el-table-column prop="num" label="数量（份）" align="center"></el-table-column>
      <el-table-column prop="arrivalTimes" label="到账次数（次）" align="center"></el-table-column>
      <el-table-column prop="giftTimes" label="赠送次数（次）" align="center"></el-table-column>
      <el-table-column prop="payTime" label="支付时间" align="center"></el-table-column>
      <!-- 支付渠道 -->
      <el-table-column prop="rgChannel" label="充值渠道" align="center" v-slot="{ row }">
        <div v-if="row.rgChannel === '00'">
          小程序
        </div>
        <div v-else-if="row.rgChannel === '01'">
          后台
        </div>
        <div v-else>--</div>
      </el-table-column>
      <el-table-column prop="payMethod" label="支付方式" align="center">
        <template scope="{ row }">
          <!-- 原逻辑：<span>{{row.payMethod == 'weixin_bowser' ? '微信支付' : ''}}</span>-->
          <!-- 渠道小程序且是微信支付 -->
          <span v-if="row.payMethod == 'weixin_bowser' && row.rgChannel === '00'">
            微信支付
          </span>
          <span v-else-if="row.payMethod == 'merchant_balance' && row.rgChannel === '00'">
            余额支付
          </span>
          <!-- 渠道后台取提交的充值方式 -->
          <span v-else-if="row.rgChannel === '01'">
            {{ row.payeeWay || '--' }}
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- 查看凭证 -->
      <el-table-column prop="rgChannel" label="收款凭证" align="center" v-slot="{ row }">
        <div v-if="Array.isArray(row.voucherList) ? row.voucherList.length : false">
          <img :src="row.voucherList[0]"
            style="display: block;margin: 0 auto;width: 70px;height: 70px;object-fit: contain" />
          <p class="lv_fc_line" style="margin-top: 10px;" @click="voucherView(row)">共{{ row.voucherList.length }}张</p>
        </div>
        <div v-else>--</div>
      </el-table-column>
      <!-- 备注 -->
      <el-table-column prop="note" label="备注" align="center" v-slot="{ row }">
        <el-tooltip class="item" effect="dark" :content="row.note" placement="top">
          <div class="lv_ycyc2">
            {{ row.note || '--' }}
          </div>
        </el-tooltip>
      </el-table-column>
    </el-table>
    <!-- 下账 -->
    <el-table v-if="tabType === '01'" key="down" :data="tableList" border style="width: 100%" id="excelOrder">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="orderNo" label="下账单号" align="center"></el-table-column>
      <el-table-column prop="merchantName" label="商家名称" align="center"></el-table-column>
      <el-table-column prop="num" label="下账次数(次)" align="center"></el-table-column>
      <el-table-column prop="price" label="折算金额(元)" align="center"></el-table-column>
      <el-table-column prop="price" label="操作人" align="center">
        <template slot-scope="{ row }">
          {{ row.platformAdminName }}-{{ row.platformAdminAcc }}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="下账时间" align="center"></el-table-column>
      <el-table-column prop="cause" label="下账原因" align="center" v-slot="{ row }">
        <el-tooltip class="item" effect="dark" :content="row.cause" placement="top">
          <div class="lv_ycyc2">
            {{ row.cause || '--' }}
          </div>
        </el-tooltip>
      </el-table-column>
    </el-table>
    <div class="orderPagina">
      <el-pagination background :current-page="pages.pageNum" :page-size="10" layout="total, prev, pager, next,jumper"
        :total="total" class="el-pagination-c" @current-change="handleCurrentChange" />
    </div>
    <PhotoPreview :photoVisible="photoVisible" :photoArr="currentData.voucherList" title="查看凭证"
      @close="() => photoVisible = false" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import PhotoPreview from "@/components/common/PhotoPreview.vue";
import moment from "moment";
export default {
  name: "OrderTable",
  components: { PhotoPreview },
  // components:{
  //   'LookImg': () => import('@/components/lookImg')
  // },
  props: {
    search: {
      type: Object,
      default: () => { return {} }
    },
    tabType: {
      type: String,
      default: '00',
    },
  },
  data() {
    return {
      // 凭证照片
      photoVisible: false,
      // 当前数据
      currentData: {
        voucherList: []
      },
      // 上账参数
      pages: {
        endTime: '',
        merchantName: '',
        orderNo: '',
        pageNum: 1,
        pageSize: 10,
        staffMobile: '',
        startTime: ''
      },
      // 下账参数
      pages2: {
        endTime: '',
        merchantId: '',//下账回收商
        adminId: '',//下账人
        orderNo: '',//下账单号
        pageNum: 1,
        pageSize: 10,
        startTime: ''
      },
      total: 0,
      tableList: []
    };
  },
  created() {
    if (this.$route.query.type) {
      this.tabType = this.$route.query.type
    }
    const startT = moment().day(moment().day()).format("YYYY-MM-DD 00:00:00");
    const endT = moment().format("YYYY-MM-DD 23:59:59");
    let times2 = [startT, endT]
    if (this.tabType === '00') {
      this.pages.startTime1 = moment(times2[0]).startOf("day").format("x")
      this.pages.endTime1 =  moment(times2[1]).endOf("day").format("x")
      this.getList()
    } else {
      this.pages.startTime = moment(times2[0]).startOf("day").format("x")
      this.pages.endTime = moment(times2[1]).endOf("day").format("x")
      this.getList2()
    }
  },
  computed: {
    SeachParams() {
      console.log(this.$store.state.tagsView.SeachParams);
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newval) {
      this.pages = newval
      console.log(newval, "SeachParamsSeachParams");
      this.handleCurrentChange();
    },
    tabType(val) {
      console.log(this.pages, 'table里参数444');
      console.log(val, '999999999');
      this.pages.pageNum = 1
      this.total = 0
      this.tableList = []
    }
  },
  methods: {
    // 查看凭证
    voucherView(row) {
      this.photoVisible = true;
      row.voucherList = Array.isArray(row.voucherList) ? row.voucherList : [];
      this.currentData = row;
    },
    // 上账列表
    getList() {
      let params = {
        merchantName: this.pages.merchantName,
        endTime: this.pages.endTime1,
        staffMobile: this.pages.staffMobile,
        rgChannel: this.pages.rgChannel,
        orderNo: this.pages.orderNo,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        startTime: this.pages.startTime1
      }
      _api.getPcOrderList(params).then(res => {
        if (res.code == 1) {
          this.tableList = res.data.records
          this.total = res.data.total
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      })
    },
    // 下账列表
    getList2() {
      let params = {
        adminId: this.pages.adminId,
        endTime: this.pages.endTime,
        merchantId: this.pages.merchantId,
        orderNo: this.pages.orderNo2,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        startTime: this.pages.startTime
      }
      _api.listByMinus(params).then(res => {
        if (res.code == 1) {
          this.tableList = res.data.records
          this.total = res.data.total
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      })
    },
    handleCurrentChange(val) {
      if (val) {
        this.pages.pageNum = val;
      } else {
        this.pages.currentPage = 1;
      }
      if (this.tabType === '00') {//上账
        this.getList()
      } else if (this.tabType === '01') {//下账
        this.getList2()
      }

    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.OrderTable {
  .orderPagina {
    float: right;
    margin: 50px;
  }

  .order_list {
    .line_top {
      background-color: white;
      width: 100%;
      height: 10px;
    }

    .table_title {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: space-between;

      .left {
        width: 60%;
        display: flex;
        position: relative;
        justify-content: space-between;

        div {
          >div {
            width: 32px;
            height: 22px;
            background: #21d8c2;
            border-radius: 3px 11px 11px 3px;
            color: white;
            line-height: 22px;
            position: absolute;
            text-align: center;
            top: 0;
            left: -5px;
            margin-right: 10px;
          }

          >span {
            font-family: FZLanTingHei-DB-GBK;
            font-size: 14px;
            font-weight: 400;
            color: #0981ff;
          }

          >input {
            border: none;
            background: none;
            color: #0981ff;
            padding: 0;
            width: 140px !important;
            font-size: 14px;
          }

          >.copy {
            width: 36px;
            height: 16px;
            display: inline-block;
            line-height: 16px;
            text-align: center;
            background: #ff6600;
            color: #fff;
            margin-left: 8px;
            border-radius: 4px;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }

      .right {
        display: flex;
        color: #fff;

        .rightTrue {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: #ff687b;
          margin-right: 10px;
          border-radius: 5px;
          text-align: center;
        }

        .rightFalse {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: #ffaf29;
          margin-right: 10px;
          border-radius: 5px;
          text-align: center;
        }

        .rightMid {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: rgba(102, 204, 153, 1);
          margin-right: 10px;
          border-radius: 5px;
          text-align: center;
        }
      }
    }
  }
}

// 表格样式+
.position {
  width: max-content;
  display: block;
  padding: 0px 4px;
  font-size: 14px;
  background-color: rgba(102, 204, 153, 1);
  margin: 0 auto;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

.images-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-box>img {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}

.images-box>span {
  color: #0981FF;
  cursor: pointer;
}

.color-green {
  color: rgba(102, 204, 153, 1)
}

.color-red {
  color: #ff687b
}

.text-down {
  text-decoration: underline
}

// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981FF;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer
    }

    .img-tips-default {
      background: #fff;
      color: #0981FF;
    }

    .img-tips-choose {
      background: #0981FF;
      color: #fff;
    }

  }

  // 列表
}

.isdeal {
  background: #ff687b;
  margin-right: 5px;
  display: inline-block;
}

.sec_img {
  width: 20vw;
  height: 80vh;
  margin: auto;

  img {
    width: 20vw;
    height: 80vh;
    object-fit: contain;
  }
}
</style>
